import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Header from "./../components/Header";
import PageBanner from "../components/PageBanner";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import { AboutUs } from "../utils/SidebarMenu";

function TermAndConditions(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <PageBanner name="Terms & Conditions of Faircent Pro Lending Partners" />
      <div className="internal-page">
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
                <Card>
                    <CardContent>
                        <img
                        src="/assets/images/partners/dmi.jpg"
                        width="200"
                        alt=""
                        />
                        <Typography variant="h6" gutterBottom>
                            <strong>Brand Name: DMI FINANCE</strong>
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} gutterBottom>
                            Legal Name (Business Registered Name): DMI FINANCE PRIVATE
                            LIMITED
                        </Typography>
                        <Typography>
                            Link for T&C:{" "}
                            <a href="https://www.dmifinance.in/msme-undertaking-tnc/" target="_blank">
                                https://www.dmifinance.in/msme-undertaking-tnc/
                            </a>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3}>
              <Sidebar sidebar={AboutUs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TermAndConditions;
